<script setup
        lang="ts">
import { watch }                               from 'vue';
import { useToastStore }                       from '@reshepe/stores';
import Toast, { type ToastPassThroughOptions } from 'primevue/toast';
import { useToast }                            from 'primevue/usetoast';

const toast = useToast();
const store = useToastStore();

const LIFETIME = 5000;

watch(
    store.toasts,
    (toasts) => {
        for (const t of toasts.filter((t) => t.active)) {
            toast.add({
                severity: t.type,
                summary:  t.content,
                life:     LIFETIME,
            });

            store.remove(t);
        }
    },
    { immediate: true },
);
</script>

<template>
    <Toast :pt="{
               root: [
                   'w-full rounded-md',
                   '!bottom-0 right-0',
                   'z-9',
                   'opacity-1',
                   'mobile-m:right-1 mobile-m:w-96 mobile-m:min-w-16 mobile-m:max-w-full',
                   '-translate-x-2/4',
               ],
               message: ({ props }) => ([
                   'w-full my-4 rounded-md',
                   'bg-black-800 shadow-lg',
                   'ring-1 ring-inset ring-black-900 ring-offset-0',
                   'mt-4',
                   'border-l-4',
                   {
                       'border-info-500': props.message?.severity === 'info',
                       'border-success-500': props.message?.severity === 'success',
                       'border-warning-500': props.message?.severity === 'warn',
                       'border-error-500': props.message?.severity === 'error',
                   }
               ]),
               messageContent: [
                   'relative',
                   'flex items-center',
                   'p-3 my-4',
               ],
               messageIcon: ({ props }) => ([
                   'w-6 h-6',
                   'text-lg leading-none mr-2 shrink-0',
                   {
                       'text-success-500': props.message?.severity === 'success',
                       'text-error-500': props.message?.severity === 'error',
                       'text-info-500': props.message?.severity === 'info',
                       'text-warning-500': props.message?.severity === 'warn',
                   }
               ]),
               messageText: [
                   'flex-1',
                   'ml-2',
                   'leading-6',
                   'text-sm text-white-500 font-helvetica',
               ],
               summary: [
                   'block'
               ],
               detail: [
                   'hidden',
               ],
               closeButton: [
                   'relative',
                   'flex items-center justify-center',
                   'w-8 h-8',
                   'ml-auto',
                   'rounded-full',
                   'bg-transparent outline-none',
                   'transition duration-200 ease-in-out',
                   'overflow-hidden',
                   'group/close',
                   'hover:bg-transparent outline-none',
                   'focus:shadow-none',
               ],
               closeIcon: [
                   'text-white-700',
                   'transition-colors duration-200 ease-in-out',
                   'group-hover/close:text-white-500',
               ],
               transition: {
                   enterFromClass: 'opacity-0 translate-y-2/4',
                   enterActiveClass: 'transition-[transform,opacity] duration-300',
                   leaveFromClass: 'max-h-[1000px]',
                   leaveActiveClass: '!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s]',
                   leaveToClass: 'max-h-0 opacity-0 mb-0',
               },
           } satisfies ToastPassThroughOptions"
           position="bottom-center" />
</template>
