<script setup
        lang="ts">
import { useAuthStore }   from '@stores/auth.store';
import { useThemeStore }  from '@reshepe/stores';
import { useNProgress }   from '@vueuse/integrations/useNProgress';
import { onMounted, ref } from 'vue';
import { useRouter }      from 'vue-router';
import { WebVitals }      from '@reshepe-web-vitals/vue';
import Toast              from '@reshepe/ui/toast.vue';

useThemeStore();
const { isLoading } = useNProgress();
const router = useRouter();
const authStore = useAuthStore();

router.onError((error, to) => {
    console.log(error.message);
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location.href = to.fullPath;
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (from.path === '/auth/login' && to.meta.requires_auth && !authStore.is_logged_in) {
        next(from.fullPath);
    } else {
        if (to.path !== from.path) {
            isLoading.value = true;
        }

        if (to.meta.redirect) {
            next(to.meta.redirect);
            if (to.meta.redirect === from.path) {
                isLoading.value = false;
            }
        } else {
            if (to.meta.requires_auth && !authStore.is_logged_in) {
                next('/auth/login?r=' + to.fullPath);
            } else if (authStore.is_logged_in) {
                if (to.meta.hide_for_logged_in) {
                    next('/');
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
});

window.addEventListener('vite:preloadError', (e: Event): void => {
    console.log('vite:preloadError', e);

    e.preventDefault();

    window.location.reload();
});

const loaded = ref<boolean>(false);
const dev = ref<boolean>(import.meta.env.MODE === 'development');
const preview = ref<boolean>(import.meta.env.VITE_VERCEL_ENV === 'preview');

const console_messages = (): void => {
    console.info(
        '01110100 01101000 01101001 01110011 00100000 01100111 01110101 01111001 00100000 01100110 01110101 01100011 01101011 01110011',
    );

    console.info(`%c
⠄⠄⠄⠄⠄⠄⠄⢀⣠⣶⣾⣿⣶⣦⣤⣀⠄⢀⣀⣤⣤⣤⣤⣄⠄⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⢀⣴⣿⣿⣿⡿⠿⠿⠿⠿⢿⣷⡹⣿⣿⣿⣿⣿⣿⣷⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⣾⣿⣿⣿⣯⣵⣾⣿⣿⡶⠦⠭⢁⠩⢭⣭⣵⣶⣶⡬⣄⣀⡀⠄⠄
⠄⠄⠄⡀⠘⠻⣿⣿⣿⣿⡿⠟⠩⠶⠚⠻⠟⠳⢶⣮⢫⣥⠶⠒⠒⠒⠒⠆⠐⠒
⠄⢠⣾⢇⣿⣿⣶⣦⢠⠰⡕⢤⠆⠄⠰⢠⢠⠄⠰⢠⠠⠄⡀⠄⢊⢯⠄⡅⠂⠄
⢠⣿⣿⣿⣿⣿⣿⣿⣏⠘⢼⠬⠆⠄⢘⠨⢐⠄⢘⠈⣼⡄⠄⠄⡢⡲⠄⠂⠠⠄
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣥⣀⡁⠄⠘⠘⠘⢀⣠⣾⣿⢿⣦⣁⠙⠃⠄⠃⠐⣀
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣋⣵⣾⣿⣿⣿⣿⣦⣀⣶⣾⣿⣿⡉⠉⠉
⣿⣿⣿⣿⣿⣿⣿⠟⣫⣥⣬⣭⣛⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠄
⣿⣿⣿⣿⣿⣿⣿⠸⣿⣏⣙⠿⣿⣿⣶⣦⣍⣙⠿⠿⠿⠿⠿⠿⠿⠿⣛⣩⣶⠄
⣛⣛⣛⠿⠿⣿⣿⣿⣮⣙⠿⢿⣶⣶⣭⣭⣛⣛⣛⣛⠛⠛⠻⣛⣛⣛⣛⣋⠁⢀
⣿⣿⣿⣿⣿⣶⣬⢙⡻⠿⠿⣷⣤⣝⣛⣛⣛⣛⣛⣛⣛⣛⠛⠛⣛⣛⠛⣡⣴⣿
⣛⣛⠛⠛⠛⣛⡑⡿⢻⢻⠲⢆⢹⣿⣿⣿⣿⣿⣿⠿⠿⠟⡴⢻⢋⠻⣟⠈⠿⠿
⣿⡿⡿⣿⢷⢤⠄⡔⡘⣃⢃⢰⡦⡤⡤⢤⢤⢤⠒⠞⠳⢸⠃⡆⢸⠄⠟⠸⠛⢿
⡟⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠁⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢸
`, 'color: green;');

    if (preview.value) {
        console.log(import.meta.env);
    }
};

onMounted(async () => {
    document.getElementById('app')?.classList.remove('hidden');
    document.getElementById('app')?.classList.add('block');
    document.getElementById('loader')?.remove();

    if (!dev.value) {
        console_messages();
    }

    if (authStore.is_logged_in) {
        await authStore.me();
    }

    loaded.value = true;
});
</script>

<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
    <Toast />
    <WebVitals api-key="pk_7bb5af98-f804-4a71-9673-be36ba7e3371"
               :development="dev || preview"
               report-soft-navigation />
</template>
